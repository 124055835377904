var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Opname export")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced" },
              [
                _vm.isLoading
                  ? _c(
                      "v-layout",
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("LoaderCard", {
                              attrs: {
                                flat: "",
                                type: "spinner--center",
                                minHeight: "300px"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isLoading
                  ? _c(
                      "v-layout",
                      { attrs: { wrap: "", "justify-center": "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs6: "" } },
                          [
                            _c("ReportFilter", {
                              attrs: {
                                cssClass: "with-border",
                                multiple: false
                              },
                              model: {
                                value: _vm.selectedReport,
                                callback: function($$v) {
                                  _vm.selectedReport = $$v
                                },
                                expression: "selectedReport"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs6: "", "text-right": "" } },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  icon: "picture_as_pdf",
                                  disabled: _vm.isDisabled,
                                  color: "primary",
                                  small: "true"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.onClickCreate($event)
                                  }
                                }
                              },
                              [_vm._v(" Exporteren ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }