import ReportFilter from '@/components/filters/report-filter/ReportFilter.vue';
import { Component, Vue } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { Report } from '@/models/Report';
import { downloadExcel } from '@/support/Client';

@Component<OpnameExport>({
  components: {
    ReportFilter,
  },
})
export default class OpnameExport extends Vue {
  // File structure for Typescript files in your Vue project
  // https://tutorials.atabix.com/frontend/typscript_file_structure/

  // #region @Props
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties
  public $pageTitle = 'Opname export';

  protected isLoading = false;

  protected selectedReport = '';
  // #endregion

  // #region Lifecycle Hooks / Init
  public mounted(): void {
    this.initializeBreadcrumb();
  }

  // #endregion

  // #region Class methods
  protected initializeBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Opname export' }],
    });
  }
  // #endregion

  // #region Async methods

  protected async onClickCreate(): Promise<void> {
    if (! this.selectedReport) {
      return;
    }
    this.isLoading = true;

    try {
      const url = new Report().assessmentExportUrl(this.selectedReport);
      await downloadExcel({ url, accept: 'application/json' });
    } catch (error) {
      ErrorHandler.network(error);
    }
    this.isLoading = false;
  }
  // #endregion

  // #region Getters & Setters
  protected get isDisabled(): boolean {
    return ! this.selectedReport;
  }
  // #endregion

  // #region @Watchers
  // #endregion
}
